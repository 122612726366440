<script>
import { AgeGroupType } from '@/utils/enums/AgeGroupType';
import EventsService from '@/service/events.service';
import { PeriodSessionType } from '@/utils/enums/PeriodSessionType';
import dayJs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

export default {
  name: 'list-period-session-name',
  data() {
    return {
      tabulacao: null,
      polling: null,
      loading: false,
      showDateMenu: false,
      searchDate: [],
      searchDateString: '',
      searchStatus: undefined,
      periodSessionType: PeriodSessionType,
      ageGroupType: AgeGroupType,
      totalItems: 0,
      options: {
        itemsPerPage: 30,
        sortBy: ['count'],
        sortDesc: [true],
        search: '',
      },

      headers: [
        { text: 'Turma', value: 'student_class_name', sortable: false },
        { text: 'Período', value: 'student_class_session', sortable: false },
        { text: 'Quantidade', value: 'count', sortable: false, align: 'center' },
      ],

      items: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },

    searchDate: {
      handler() {
        this.fetch();
      },
    },

    searchStatus: {
      handler() {
        this.fetch();
      },
    },
  },

  created() {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    this.searchDate = [startOfMonth, endOfMonth];

    this.pollData();
  },

  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.fetch();
      }, 15000);
    },

    async fetch() {
      this.loading = true;

      try {
        const params = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          search: this.options.search,
        };

        params['filter.device.accessType'] = 'LUNCH';

        if (this.searchDate[0]) {
          let searchDate = cloneDeep(this.searchDate);
          this.searchDateString = `${this.formatDate(searchDate[0])}`;
          params['filter.date'] = `$btw:${searchDate[0]} 00:00:00.001,${searchDate[0]} 23:59:59.999`;

          if (this.searchDate[1]) {
            searchDate = searchDate.sort((a, b) => {
              return new Date(a) - new Date(b);
            });
            this.searchDateString = `${this.formatDate(searchDate[0])} ~ ${this.formatDate(searchDate[1])}`;
            params['filter.date'] = `$btw:${searchDate[0]} 00:00:00.001,${searchDate[1]} 23:59:59.999`;
          }
        }

        if (this.searchStatus && this.searchStatus !== 'Todos') {
          params['filter.authorized'] = `$eq:${this.searchStatus === 'Autorizado'}`;
        }

        if (this.options.sortBy.length > 0) {
          params.sort = `${this.options.sortBy[0]},${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
        }

        this.items = await EventsService.findBySessionsName(params);
        this.page = 1;
        this.totalItems = this.items.length;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    getStatusOptions(status) {
      let text = status ? 'Autorizado' : 'Negado';
      let color = 'white';
      let background = 'black';

      if (status) {
        color = 'success lighten-3';
        background = 'success darken-3';
      } else {
        color = 'error lighten-3';
        background = 'error darken-3';
      }

      return {
        text,
        color,
        background,
      };
    },

    formatDate(date) {
      if (!date) return null;
      return dayJs(date).format('DD/MM/YY');
    },

    beforeDestroy() {
      clearInterval(this.polling);
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <div>
      <h4 class="pb-1">Total por turma</h4>
      <v-card>
        <v-card-text>
          <v-row class="mb-0">
            <v-col cols="7"> </v-col>
            <v-col cols="5">
              <v-menu
                v-model="showDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchDateString"
                    append-icon="mdi-calendar"
                    label="pesquisar data"
                    readonly
                    color="white"
                    hide-details="auto"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="searchDate" range no-title scrollable></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            :footer-props="{ 'items-per-page-options': [10, 30, 50, 100] }"
            class="no-line-row"
          >
            <template v-slot:[`item.student_class_session`]="{ item }">
              {{ item.student_class_session && periodSessionType[item.student_class_session] | empty }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style>
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #2b385a;
}

.v-tab--active {
  color: white !important;
}

.v-tabs-slider {
  background-color: white;
}
</style>
